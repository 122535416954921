//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import {
  NameCellRenderer,
  DesCellRenderer,
  CreatedCellRenderer,
  TemplateCellRenderer,
  PathCellRenderer,
  PhotoCellRenderer,
  ActionsCellRenderer
} from './grid';
import { PAGE_STATE } from '@/enum';
import SearchBox from '@/views/project/page/components/SearchBox.vue';

export default {
  components: {
    PageHeader,
    SearchBox,
    NameCellRenderer,
    DesCellRenderer,
    CreatedCellRenderer,
    TemplateCellRenderer,
    PathCellRenderer,
    PhotoCellRenderer,
    ActionsCellRenderer
  },
  data() {
    return {
      pages: [],
      queryParam: {
        slug: '',
        title: ''
      },
      currentProject: {},
      loading: false,
      context: { componentParent: this },
      pagination: { current: 1, pageSize: 200, total: 0 },
      selectedPages: [],
      pageArchiveVisible: false,
      pageImportVisible: false
    };
  },
  created() {
    const { title, slug } = this.$route.query;
    this.queryParam = {
      title: title || '',
      slug: slug || ''
    };
    this.pagination.pageSize = +this.$route.query.size || 200;
    this.pagination.current = +this.$route.query.page || 1;
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    columnDefs() {
      return [
        {
          headerName: 'project.grid.Photo',
          field: 'photo',
          width: 150,
          lockPosition: true,
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'page.grid.Name',
          field: 'name',
          lockPosition: true,
          cellRenderer: 'NameCellRenderer'
        },
        {
          headerName: 'project.grid.Path',
          field: 'slug',
          width: 120,
          lockPosition: true,
          cellRenderer: 'PathCellRenderer'
        },
        {
          headerName: 'project.grid.Template',
          field: 'type',
          width: 150,
          lockPosition: true,
          cellRenderer: 'TemplateCellRenderer'
        },
        {
          headerName: 'project.grid.Description',
          field: 'description',
          lockPosition: true,
          cellRenderer: 'DesCellRenderer'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          lockPosition: true,
          cellRenderer: 'CreatedCellRenderer'
        },
        {
          resizable: false,
          headerName: 'project.grid.Actions',
          field: 'actions',
          width: 120,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'ActionsCellRenderer'
        }
      ];
    },
    filter() {
      const { title, slug } = this.queryParam;
      let where = {
        ...(title && { name: { ilike: `%${title}%` } }),
        ...(slug && { or: [{ slug: { ilike: `%${slug}%` } }, { id: parseInt(slug) }] }),
        status: 'ARCHIVE'
      };
      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        order: 'order_by DESC'
      };
      return filter;
    },
    pageArchiveEnable() {
      return this.selectedPages.length ? true : false;
    },
    projectId() {
      return this.$route.params.projectId;
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.pages = await this.$s.api.page.find(this.projectId, { filter: this.filter });
        const count = await this.$s.api.page.count(this.projectId, this.filter.where);
        // unshift the primary page into top of pages array
        const primaryPage = this.pages.find(page => page.state === PAGE_STATE.PRIMARY);
        if (primaryPage) {
          this.pages.splice(this.pages.indexOf(primaryPage), 1);
          this.pages.unshift(primaryPage);
        }
        this.currentProject = await this.$s.api.project.getProjectById(
          this.userInfo.company_id,
          this.projectId
        );
        this.pagination = { ...this.pagination, total: count?.count };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openArchiveModal() {
      this.pageArchiveVisible = true;
    },
    handleReset() {
      this.queryParam = { title: '', slug: '' };
      this.handleSearch();
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeSlug(value) {
      this.queryParam.slug = value;
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    handleChangePage({ page, pageSize }) {
      this.pagination = { ...this.pagination, current: page, pageSize };
      this.getData();
    },
    handleSelectPage(event) {
      let nodes = event.api.getSelectedNodes();
      this.selectedPages = nodes.map(node => node.data);
    }
  }
};
