//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    created() {
      return this.params.data.created;
    },
    pageId() {
      return this.params.data.id;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    pathToCodes() {
      return `/archived-projects/${this.projectId}/pages/${this.pageId}/codes`;
    },
    pageTemplate() {
      return this.params.data.template;
    },
  }
});
