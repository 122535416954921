var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("page-header", {
        staticClass: "mb-xs",
        attrs: { "show-back-icon": "", title: _vm.currentProject.name },
        on: {
          back: function($event) {
            return _vm.$router.go(-1)
          }
        }
      }),
      _c("search-box", {
        staticClass: "pb-sm",
        attrs: { queryParam: _vm.queryParam },
        on: {
          changeTitle: _vm.changeTitle,
          changeSlug: _vm.changeSlug,
          handleSearch: _vm.handleSearch,
          handleReset: _vm.handleReset
        }
      }),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _c("SGrid", {
              ref: "sGrid",
              attrs: {
                "grid-name": "project-page-archive",
                rowData: _vm.pages,
                enableCheckbox: false,
                columnDefs: _vm.columnDefs,
                gridContext: _vm.context,
                pagination: _vm.pagination,
                rowHeight: 124
              },
              on: {
                onPageChange: _vm.handleChangePage,
                selectionChanged: _vm.handleSelectPage
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }